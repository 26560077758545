import React, {useState, useEffect} from 'react';
import bridge from '@vkontakte/vk-bridge';
import View from '@vkontakte/vkui/dist/components/View/View';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import '@vkontakte/vkui/dist/vkui.css';

import Home from './panels/Home';
import Persik from './panels/Persik';
import Acter from './panels/Acter';
import Photo from './panels/Photo';
import "./panels/Persik.css";
import queryString from 'query-string';
import Errors from "./panels/Errors";
import {Button, ModalCard, ModalRoot} from "@vkontakte/vkui";
import {Icon56NotificationOutline} from "@vkontakte/icons";
import { Icon56CakeCircleFillRaspberryPink } from '@vkontakte/icons';
const parsed = queryString.parse(window.location.search);
const vk_id = parseInt(parsed.vk_user_id);
const not_android = parsed.vk_platform === "mobile_web";
const canDown = parsed.vk_platform === "desktop_web" || parsed.vk_platform === "mobile_web";
let urrrl = null
let BreakException = {};
let oldScreen = "";
const notifications = 'notifications';
const App = () => {
    const [activePanel, setActivePanel] = useState('home');
    const [fetchedUser, setUser] = useState(null);
    // const [popout, setPopout] = useState(null);//
    const [popout, setPopout] = useState(<ScreenSpinner size='large'/>);//
    const [activeStory,setActiveStory] = useState('mans');

    const [acter,setActer] = useState({});
    const [images,setImages] = useState([]);
    const [image,setImage] = useState("");
    const [friend,setFriend] = useState({});

    const [error,setError] = useState("")

    const [man_photos, setMan_Photos] = useState(null);
    const [other_photos, setOther_Photos] = useState(null);
    const [woman_photos, setWoman_Photos] = useState(null);
    const [birthday_photos, setBirthday_Photos] = useState(null); //добавил
    const [mans, setMans] = useState(null);
    const [womans, setWomans] = useState(null);
    const [birthdays, setBirthdays] = useState(null); //добавил
    const [others, setOthers] = useState(null);
    const [config, setConfig] = useState(null);

    const [homepageScroller, setHomepageScroller] = useState(null);
    const [imagesHomepageScroller, setImagesHomepageScroller] = useState(null);

    const [activeModal, setActiveModal] = useState(null);

    const [scrollY,setScrollY] = useState([
        0,0,0
    ])


    useEffect(()=>{
        if (homepageScroller === null) {
            fetch('https://kkklo.ru/json/home_page/home_page.json')
                .then(response => response.json())
                .then(json => {
                    setHomepageScroller(json)
                });
        }
        if (imagesHomepageScroller === null) {
            fetch('https://kkklo.ru/json/home_page/img_home_page.json')
                .then(response => response.json())
                .then(json => {
                    setImagesHomepageScroller(json)
                });
        }
        if(man_photos == null)
        fetch('https://kkklo.ru/json/imgman.json')
            .then(response => response.json())
            .then(json => {

                setMan_Photos(json)}
            );
        if(other_photos == null)
        fetch('https://kkklo.ru/json/imgother.json')
            .then(response => response.json())
            .then(json => {

                setOther_Photos(json)}
            );
        if(woman_photos == null)
        fetch('https://kkklo.ru/json/imgwoman.json')
            .then(response => response.json())
            .then(json => {

                setWoman_Photos(json)}
            );
//ниже добавил
            if(birthday_photos == null)
            fetch('https://kkklo.ru/json/imgbirthday.json')
                .then(response => response.json())
                .then(json => {

                    setBirthday_Photos(json)}
                );

        if(mans == null)
        fetch('https://kkklo.ru/json/man.json')
            .then(response => response.json())
            .then(json => {

                setMans(json)}
            );
        if(others == null)
        fetch('https://kkklo.ru/json/other.json')
            .then(response => response.json())
            .then(json => {
                setOthers(json)}
            );
        if(womans == null)
        fetch('https://kkklo.ru/json/woman.json')
            .then(response => response.json())
            .then(json => {
                setWomans(json)}
            ); //добавил ниже
            if(birthdays == null)
            fetch('https://kkklo.ru/json/birthday.json')
                .then(response => response.json())
                .then(json => {
                    setBirthdays(json)}
                );
        if(config == null)
        fetch('https://kkklo.ru/json/config.json')
            .then(response => response.json())
            .then(json => {
                setPopout(null);
                setConfig(json)}
            );

    },[]);

    useEffect(() => {
        bridge.send('VKWebAppSetSwipeSettings', {"history": true});
        async function fetchData() {
            const user = await bridge.send('VKWebAppGetUserInfo');
            setUser(user);
            setPopout(null);
        }
        fetchData();
        window.addEventListener('online', () => setActivePanel(oldScreen));
        window.addEventListener('offline', () => {
            oldScreen= activePanel
            setError("Соединение с интернетом отсутствует")
            setActivePanel("error")
        });
    }, []);


    useEffect(() => {

        // ниже раскомментировать (оповещения, плюс подписка на группу)
        // async function joinGroup() {
        //     if (config != null) {
        //         const keys = await bridge.send('VKWebAppStorageGet', {'keys': ['imagecatalog_join_timestamp_fix', 'imagecatalog_join_isnew_fix']});

        //         const timestamp = keys.keys.find(x => x.key === 'imagecatalog_join_timestamp_fix');
        //         const isNew = keys.keys.find(x => x.key === 'imagecatalog_join_isnew_fix');

        //         if (isNew.value === '' && timestamp.value === '') {
        //             await bridge.send('VKWebAppStorageSet', {'key': 'imagecatalog_join_timestamp_fix', 'value': `${Date.now() + 43200000}`});
        //             await bridge.send('VKWebAppStorageSet', {'key': 'imagecatalog_join_isnew_fix', 'value': `yes`});
        //         }

        //         else {
        //             if (isNew.value === 'yes' && Number(timestamp.value) <= Date.now()) {
        //                 await bridge.send('VKWebAppStorageSet', {'key': 'imagecatalog_join_timestamp_fix', 'value': `${Date.now() + 43200000}`});
        //                 setTimeout(() => bridge.send('VKWebAppJoinGroup', {'group_id': config.grp}), 4000);

        //                 if (parsed.vk_are_notifications_enabled === '0') {
        //                     setTimeout(() => {
        //                         setActiveModal(notifications);
        //                     }, 12000);
        //                 }
        //             }
        //         }
        //     }
        // }
        // joinGroup();
 // выше раскомментировать (оповещения, плюс подписка на группу)       



       /* setTimeout(() => bridge.send('VKWebAppJoinGroup', {'group_id': config.grp}), 1500); - так стало*/
       /* bridge.send('VKWebAppJoinGroup', {'group_id': config.grp}); - так было*/
      /* 86400000 - Сутки*/
     /*  43200000 - 12 часов*/
      /* 120000   - 2 минуты*/
      /* 15000   - 15 секунд*/
     /* 8000 - 8 секунд*/
        

// ниже раскомментировать (оповещения, плюс подписка на группу)
        //  if (parsed.vk_are_notifications_enabled === '0') {
        //     setTimeout(() => {
        //         setActiveModal(notifications);
        //     }, 14000);
        // }
        // setTimeout(()=> bridge.send("VKWebAppJoinGroup", {"group_id": config.grp}),4000);
// выше раскомментировать (оповещения, плюс подписка на группу)

    },[config])
    const go = e => {
        setActivePanel(e.currentTarget.dataset.to);
    };
    const funActer = e => {
        let who = e.currentTarget.dataset.who;
        let id = e.currentTarget.dataset.acter;
        if(who === "man"){
           setImages(man_photos[id]);
            mans.forEach((element, key) => {
                if(parseInt(element.id_cat) == id){
                    id = key;
                }
            });
            setActer(mans[id]);



            setActivePanel("acter");
        }else if(who === "woman"){
            setImages(woman_photos[id]);
            womans.forEach((element, key) => {
                if(parseInt(element.id_cat) == id){
                    id = key;
                }
            });
            setActer(womans[id]);

            // Ниже добавил

            setActivePanel("acter");
        }else if(who === "birthday"){
            setImages(birthday_photos[id]);
            birthdays.forEach((element, key) => {
                if(parseInt(element.id_cat) == id){
                    id = key;
                }
            });
            setActer(birthdays[id]);





            setActivePanel("acter");
        }else if(who === "homepageScroller"){
            setImages(imagesHomepageScroller[id]);

            homepageScroller.forEach((element, key) => {
                if(parseInt(element.id_cat) == id){
                    id = key;
                }
            });
            setActer(homepageScroller[id]);

            setActivePanel("acter");
        }else{
            setImages(other_photos[id]);
            others.forEach((element, key) => {
                if(parseInt(element.id_cat) == id){
                    id = key;
                }
            });
            setActer(others[id]);
            setActivePanel("acter");
        }
    }

    const allowToSendNotifications = () => {
        setActiveModal(null);
        bridge.send("VKWebAppAllowNotifications")
            .then(async () => {
                fetch('https://kkklo2not.herokuapp.com/subscribe/' + fetchedUser.id);
            })
    }

    const modal = (
<ModalRoot activeModal={activeModal}>
  <ModalCard
    id={notifications}
    onClose={() => setActiveModal(null)}
    icon={<Icon56CakeCircleFillRaspberryPink />}
    header="Напоминать о праздниках?"
    caption="Для того, чтобы напомнить Вам о важных праздниках и событиях - нужно включить уведомления"
    actions={[
      {
        title: "Закрыть",
        mode: "secondary",
        action: () => setActiveModal(null),
      },
      {
        title: "Далее",
        mode: "primary",
        action: () => setActiveModal(allowToSendNotifications),
      },
    ]}
  ></ModalCard>
</ModalRoot>
    );

    return (
        <View activePanel={activePanel} popout={popout} modal={modal}>
            <Home activeStory={activeStory} setActiveStory={setActiveStory} homepageScroller={homepageScroller} birthdays={birthdays} womans={womans} others={others} acter={funActer} mans={mans} id='home' fetchedUser={fetchedUser} go={go}/>
            <Acter setActivePanel={setActivePanel} setImage={setImage} bridge={bridge} acter={acter} go={go} iamges={images} id="acter"/>
            <Photo setActivePanel={setActivePanel} canDown={canDown} not_android={not_android} bridge={bridge} photo={image} acter={acter} go={go} iamges={images} id="photo"/>
            <Persik id='persik' go={go}/>
            <Errors id={"error"} error={error}/>
        </View>
    );
}

export default App;

