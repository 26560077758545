import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
//import HorizontalScroll from 'react-scroll-horizontal'
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import {
  View,
  Epic,
  Tabbar,
  TabbarItem,
  Group,
  Header,
  CardGrid,
  Card,
  Separator,
  Caption,
  platform,
  Button,
} from "@vkontakte/vkui";
import Icon28GiftOutline from "@vkontakte/icons/dist/28/gift_outline";
import { Icon28LightbulbOutline } from "@vkontakte/icons";
import Icon28RecentOutline from "@vkontakte/icons/dist/28/recent_outline";
import { Icon28CakeCircleFillPurple } from "@vkontakte/icons";
import { Icon28CalendarCircleFillRed } from "@vkontakte/icons";
import { Icon28MessageCircleFillGreen } from "@vkontakte/icons";
import { Icon28GiftCircleFillYellow } from "@vkontakte/icons";
import bridge from "@vkontakte/vk-bridge";
import * as queryString from "querystring";

const parsed = queryString.parse(window.location.search);

const Home = ({
  id,
  go,
  acter,
  activeStory,
  setActiveStory,
  homepageScroller,
  mans,
  womans,
  birthdays,
  others,
  fetchedUser,
}) => {
  useEffect(() => {
    const popstateListener = (event) => {
      event.preventDefault();
      bridge
        .send("VKWebAppClose", { status: "success" })
        .then(console.log)
        .catch(console.error);
    };

    window.addEventListener("popstate", popstateListener, false);
    window.history.pushState({ panel: "home" }, "home");

    return () => {
      console.log("closed1");
      window.removeEventListener("popstate", popstateListener, false);
    };
  });

  useEffect(() => {
    //document.getElementById("ekke").scrollTo(120, 450);
    window.scrollTo(0, 0);
  }, [activeStory]);

  return (
    <Panel id={id}>
      <Epic
        activeStory={activeStory}
        tabbar={
          <Tabbar>
            <TabbarItem
              className="mycursor"
              onClick={() => setActiveStory("mans")}
              selected={activeStory === "mans"}
              data-story="home"
              text="Праздники"
            >
              <Icon28GiftCircleFillYellow />
            </TabbarItem>
            <TabbarItem
              className="mycursor"
              onClick={() => setActiveStory("womans")}
              selected={activeStory === "womans"}
              data-story="womans"
              text="Пожелания"
            >
              <Icon28MessageCircleFillGreen />
            </TabbarItem>
            <TabbarItem
              className="mycursor"
              onClick={() => setActiveStory("other")}
              selected={activeStory === "other"}
              data-story="other"
              text="Дни недели"
            >
              <Icon28CalendarCircleFillRed />
            </TabbarItem>
            <TabbarItem
              className="mycursor"
              onClick={() => setActiveStory("birthdays")}
              selected={activeStory === "birthdays"}
              data-story="birthdays"
              text="Дни рождения"
            >
              <Icon28CakeCircleFillPurple />
            </TabbarItem>
          </Tabbar>
        }
      >
        {mans != null && (
          <View id="mans" activePanel="mans">
            <Panel id="mans">
              <PanelHeader>Открытки и поздравления</PanelHeader>

              {homepageScroller &&
                // раскомментировать чтобы выключить скролл на пк версии
                parsed.vk_platform !== "desktop_web" && (
                  <Group
                    separator="hide"
                    style={{ height: "200px" }}
                    header={<Header mode="primary">Актуальное</Header>}
                  >
                    <ScrollMenu>
                      {homepageScroller.map((item, index) => {
                        return (
                          <div
                            data-who="homepageScroller"
                            data-acter={item.id_cat}
                            onClick={acter}
                            style={{
                              margin:
                                index === 0
                                  ? "0 0 0 10px"
                                  : homepageScroller.length - 1 === index
                                  ? "0 10px 0 0"
                                  : "",
                              padding: "8px",
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              height="110"
                              style={{ borderRadius: "4px" }}
                              src={item.img_actor}
                            />
                            <div className="noprobel"
                              style={{ fontSize: "14px", marginTop: "5px" }}
                            >
                              {item.name_actor}
                            </div>
                          </div>
                        );
                      })}
                    </ScrollMenu>
                    <Separator style={{ margin: "12px 0" }} />
                  </Group>
                )}

              <Group
                separator="hide"
                 style={{ padding: "5px"/*, marginTop: "10px"*/ }}
                header={<Header mode="primary">Ближайшие праздники</Header>}
              >
                {mans?.length > 0 &&
                  mans.map((man, index) => (
                    <Card
                      data-who="man"
                      key={index}
                      data-acter={man.id_cat}
                      onClick={acter}
                      style={{ marginTop: "10px" }}
                      size="m"
                    >
                      <div className="flex_div" style={{ height: 96 }}>
                        <img
                          className="actor_img"
                          src={man.img_actor}
                          style={{ marginLeft: "10px" }}
                        />
                        <h4>{man.name_actor}</h4>
                        <div className="date">
                          <Caption
                            className="actor_date"
                            level="2"
                            weight="medium"
                          >
                            {man.date_actor}
                          </Caption>
                        </div>
                      </div>
                    </Card>
                  ))}
              </Group>
            </Panel>
          </View>
        )}

        {womans != null && (
          <View id="womans" activePanel="womans">
            <Panel id="womans">
              <PanelHeader>Пожелания</PanelHeader>
              <Group style={{ padding: "5px" }}>
                {womans.length > 0 &&
                  womans.map((man, index) => (
                    <Card
                      data-who="woman"
                      key={index}
                      data-acter={man.id_cat}
                      onClick={acter}
                      style={{ marginTop: "20px" }}
                      size="m"
                    >
                      <div className="flex_div1" style={{ height: 96 }}>
                        <img
                          className="actor_img"
                          src={man.img_actor}
                          style={{ marginLeft: "10px" }}
                        />
                        <h4>{man.name_actor}</h4>
                      </div>
                    </Card>
                  ))}
              </Group>
            </Panel>
          </View>
        )}
        {others != null && (
          <View id="other" activePanel="other">
            <Panel id="other">
              <PanelHeader>Дни недели</PanelHeader>
              <Group style={{ padding: "5px" }}>
                {others.length > 0 &&
                  others.map((man, index) => (
                    <Card
                      data-who="other"
                      key={index}
                      data-acter={man.id_cat}
                      onClick={acter}
                      style={{ marginTop: "20px" }}
                      size="m"
                    >
                      <div className="flex_div1" style={{ height: 96 }}>
                        <img
                          className="actor_img"
                          src={man.img_actor}
                          style={{ marginLeft: "10px" }}
                        />
                        <h4>{man.name_actor}</h4>
                      </div>
                    </Card>
                  ))}
              </Group>
            </Panel>
          </View>
        )}

        {birthdays != null && (
          <View id="birthdays" activePanel="birthdays">
            <Panel id="birthdays">
              <PanelHeader>Дни рождения</PanelHeader>
              <Group style={{ padding: "5px" }}>
                {birthdays.length > 0 &&
                  birthdays.map((man, index) => (
                    <Card
                      data-who="birthday"
                      key={index}
                      data-acter={man.id_cat}
                      onClick={acter}
                      style={{ marginTop: "20px" }}
                      size="m"
                    >
                      <div className="flex_div1" style={{ height: 96 }}>
                        <img
                          className="actor_img"
                          src={man.img_actor}
                          style={{ marginLeft: "10px" }}
                        />
                        <h4>{man.name_actor}</h4>
                      </div>
                    </Card>
                  ))}
              </Group>
            </Panel>
          </View>
        )}
      </Epic>
    </Panel>
  );
};

Home.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired,
  fetchedUser: PropTypes.shape({
    photo_200: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    city: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

export default Home;
