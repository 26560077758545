import "core-js/features/map";
import "core-js/features/set";
import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import App from "./App";

// Init VK  Mini App
bridge.send("VKWebAppInit");

bridge.subscribe(({ detail: { type, data } }) => {
  if (type === "VKWebAppUpdateConfig") {
    const schemeAttribute = document.createAttribute("scheme");
    schemeAttribute.value = data.scheme ? data.scheme : "space_gray";
    document.body.attributes.setNamedItem(schemeAttribute);

    if (bridge.supports("VKWebAppSetViewSettings")) {
      bridge.send("VKWebAppSetViewSettings", {
        status_bar_style: data.scheme === "space_gray" ? "light" : "dark",
      });
    }
  }
});

ReactDOM.render(<App />, document.getElementById("root"));
if (process.env.NODE_ENV === "development") {
  import("./eruda").then(({ default: eruda }) => {}); //runtime download
}
