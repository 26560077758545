import React, { useState, useEffect } from "react";
import {
  platform,
  ANDROID,
  IOS,
  Panel,
  PanelHeader,
  Group,
  Button,
  Header,
  Textarea,
  FormStatus,
  Alert,
  Div,
  Snackbar,
  Avatar,
  Input,
} from "@vkontakte/vkui";
import PanelHeaderButton from "@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton";
import Icon28ChevronBack from "@vkontakte/icons/dist/28/chevron_back";
import Icon24Back from "@vkontakte/icons/dist/24/back";
import bridge from "@vkontakte/vk-bridge";
import Icon16Done from "@vkontakte/icons/dist/16/done";
import { Icon16ErrorCircleOutline } from "@vkontakte/icons";

const osName = platform();
const Photo = ({
  id,
  bridge,
  canDown,
  photo,
  go,
  acter,
  not_android,
  setPopout,
  setActivePanel,
}) => {
  const [mess, setMess] = useState("");
  const [friend, setFriend] = useState(0);
  const [snackbar, setSnackbar] = useState(null);

  const successAlert = (
    <Snackbar
      onClose={() => setSnackbar(null)}
      after={
        <Avatar size={24} style={{ background: "var(--accent)" }}>
          <Icon16Done fill="#fff" width={14} height={14} />
        </Avatar>
      }
    >
      Запись с открыткой успешно опубликована!
    </Snackbar>
  );

  const errorAlert = (
    <Snackbar
      onClose={() => setSnackbar(null)}
      after={
        <Avatar size={24} style={{ background: "var(--accent)" }}>
          <Icon16ErrorCircleOutline fill="#fff" width={14} height={14} />
        </Avatar>
      }
    >
      При публикации записи произошла ошибка.
    </Snackbar>
  );

  const send = (e) => {
    if (mess.length >= 0) {
      if (e.currentTarget.dataset.to === "friend") {
        bridge.send("VKWebAppGetFriends", {});
      } else {
        bridge
          .send("VKWebAppShowWallPostBox", {
            message:
           //   mess + "\n" + "❤ Больше открыток здесь -> ❤ vk.com/app7373231",
              mess + "\n" + "❤ Больше открыток здесь -> ❤ vk.com/greetcard",
            attachments: photo.id,
          })
          .then(() => setSnackbar(successAlert))
          .catch(() => setSnackbar(errorAlert));
      }
    }
  };

  const download = (e) => {
    let name = photo.url.split("/")[6];
    bridge.send("VKWebAppDownloadFile", { url: photo.url, filename: "greetcard.jpg" });
  };

  useEffect(() => {
    if (friend !== 0)
      bridge
        .send("VKWebAppShowWallPostBox", {
          message:
         //   mess + "\n" + "❤ Больше открыток здесь -> ❤ vk.com/app7373231",
            mess + "\n" + "❤ Больше открыток здесь -> ❤ vk.com/greetcard",
          attachments: photo.id,
          owner_id: friend,
        })
        .then(() => {
          setSnackbar(successAlert);
          setFriend(0);
        })
        .catch(() => {
          setSnackbar(errorAlert);
          setFriend(0);
        });
  }, [friend]);

  useEffect(() => {
    const popstateListener = (event) => {
      event.preventDefault();
      setActivePanel("acter");
      //window.history.pushState({ panel: 'acter' }, 'acter');
    };

    window.addEventListener("popstate", popstateListener, false);
    window.history.pushState({ panel: "photo" }, "photo");

    bridge.subscribe(({ detail: { type, data } }) => {
      if (type === "VKWebAppGetFriendsResult") {
        if (data.hasOwnProperty("users")) {
          if (friend === data.users[0].id) setFriend(0);
          setFriend(data.users[0].id);
        }
      }
    });

    return () => {
      console.log("closed3");
      window.removeEventListener("popstate", popstateListener, false);
    };
  }, []);
  return (
    <Panel id={id} style={{ overflowY: "hidden", overflowY: "scroll" }}>
      <PanelHeader
        left={
          <PanelHeaderButton onClick={go} data-to="acter">
            {osName === IOS ? <Icon28ChevronBack /> : <Icon24Back />}
          </PanelHeaderButton>
        }
      >
        Поделиться
      </PanelHeader>

      <img className="photo_acter" src={photo.url} />

      <Group
        header={
          <Header className="unselectable">Опубликовать запись на стену</Header>
        }
      >
        <div className="new">
          <Input
            class="heighttext"
            onChange={(val) => {
              if (val.currentTarget.value.length < 300) {
                setMess(val.currentTarget.value);
              }
            }}
            top="Любимая музыка"
            value={mess}
            placeholder="Текст для записи на стене"
          />
        </div>

        <div style={{ display: "flex" }} className="buttons">
          {!not_android && (
            <Button
              stretched
              data-to="friend"
              onClick={send}
              className="buttonJ"
              mode="primary"
            >
              Другу
            </Button>
          )}

          <Button
            stretched
            data-to="me"
            onClick={send}
            className="buttonJ"
            mode="primary"
          >
            {!not_android ? "Себе" : "Опубликовать"}
          </Button>
        </div>
        {!canDown && (
          <div style={{ display: "flex" }} className="buttons">
            <Button
              stretched
              data-to="friend"
              onClick={download}
              className="buttonJ"
              mode="secondary"
            >
              Скачать фотографию на устройство
            </Button>
          </div>
        )}
      </Group>
      {snackbar}
    </Panel>
  );
};
export default Photo;
