import React, {useState} from 'react';
import {platform, IOS, Card, Caption, Group} from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderButton from "@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton";
import {
    Placeholder
} from '@vkontakte/vkui'
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import {
    Icon56UsersOutline
} from '@vkontakte/icons';
import { Icon20FireCircleFillRed } from '@vkontakte/icons';
const osName = platform();
const Errors = ({id, error}) => {

    return (
        <Panel id={id}>
            <div className="errorname">
            <Placeholder
                icon={<Icon20FireCircleFillRed />}
                header="Ошибка"
            >
                {error}
            </Placeholder>
            </div>
        </Panel>
    );
};

export default Errors;