import React, {useEffect} from 'react';
import {platform, IOS, Card, Caption, Group, HorizontalScroll} from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderButton from "@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton";
import {
    List
} from '@vkontakte/vkui'
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import $ from "jquery";
import {state} from "../state";
const osName = platform();
const Acter = ({id, go, bridge, setImage, setActivePanel, acter, iamges, fetchedUser}) => {
    const send = e => {
        let man = {
          id:e.currentTarget.dataset.id,
          url:e.currentTarget.dataset.url
        };

        state.opened++;

        // если это третее открытие панели, то показываем рекламу
        if (state.opened >= 3) {
            bridge.send('VKWebAppShowNativeAds', { ad_format: 'interstitial' })
                .finally(() => {

                });

            state.opened = 0;
        }

        setImage(man);
        setActivePanel("photo");
    };



    useEffect(() => {
        const popstateListener = (event) => {
            event.preventDefault()
            setActivePanel('home')
            window.history.pushState({ panel: 'home' }, 'home');
        }
        setTimeout(()=>{
            window.addEventListener('popstate', popstateListener, false);
            window.history.pushState({ panel: 'acter' }, 'acter');
        },300)
        return () => {
            console.log('closed2')
            window.removeEventListener('popstate', popstateListener, false)
        }
    },[])

    const go1 = (e) => {

    }


    return (
        <Panel id={id}>
            <PanelHeader
                left={<PanelHeaderButton onClick={go} data-to="home" className="mycursor">
                    {osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
                </PanelHeaderButton>}
            >
                Открытки
            </PanelHeader>
            <List className="actor_images">
                {iamges.length > 0 && iamges.map((man, index) => (
                    <img key={index} data-id={man.id} data-url={man.url} onClick={send} className="acter_images" src={
                        man.hasOwnProperty("url") ? man.url : man
                    }/>
                ))
                }
            </List>
        </Panel>
    );
};
export default Acter;